<template>
<div>
  <div class="page-content">
    <h1>Nos Publications</h1>
    <p>Retrouver ici l'ensemble des documents produits par Drogues et société depuis sa création</p>
  </div>
  <div class="title-block red">
    <div class="page-content search-filters">
      <div style="flex: 2; padding-right: 4em">
        <v-text-field label="Rechercher une publication" dark flat v-model="search"></v-text-field>
      </div>
      <div class="filters">
        <div class="filter-set"><v-select label="Filtrer par année" :items="years" multiple dark></v-select></div>
        <div class="filter-set"><v-select label="Filtrer par thème" :items="themes" multiple dark></v-select></div>
      </div>
    </div>
  </div>
  <div class="page-content publications">

    <Block clazz="publication-wrapper" :title="publication.name" no-margin
           v-for="(publication, i) in filteredPublications" v-bind:key="`publication-${i}`">

      <template v-slot:content>
        {{publication.description}}
      </template>

      <template v-slot:header-action>
        <div style="margin-left: 1em">{{publication.year}}</div>
      </template>

      <template v-slot:footer-actions>
        <v-btn text><v-icon left>{{icons.eye}}</v-icon> Consulter</v-btn>
        <v-btn color="primary" text><v-icon left>{{icons.download}}</v-icon> Télécharger</v-btn>
      </template>
    </Block>
  </div>
</div>
</template>

<script>

import publication from "@/assets/publications/publications.json"
import Block from "../components/Block";
import { mdiEye, mdiDownloadCircleOutline } from "@mdi/js"

export default {
  name: "Publications",
  components: { Block },
  data() {
    return {
      publications: publication,
      filteredPublications: publication,
      search: null,
      icons: {
        eye: mdiEye,
        download: mdiDownloadCircleOutline
      }
    }
  },
  watch: {
    search(term) {
      this.filterPublications(term)
    }
  },
  computed: {
    years() {
      return publication.map(p => p.year)
    },
    themes() {
      return publication.flatMap(p => p.categories)
    }
  },
  methods: {
    filterPublications(term) {

      if (term && term.length > 1) {
        this.filteredPublications = this.publications.filter(publication => {


          let searchTerm = term.normalize('NFKD').replace(/[^\w]/g, '');
          let name = publication.name.normalize('NFKD').replace(/[^\w]/g, '');

          return name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1

        })
      } else {
        this.filteredPublications = this.publications
      }


    }
  }

}
</script>

<style lang="scss">
.block-header-actions {
  align-self: flex-start;
}
</style>

<style lang="scss" scoped>
@import "src/styles/colors";

.publication {
  margin-right: 1em;
}
.title-block {
  color: $white !important;
  font-weight: bold;

  .search-filters {
    display: flex;
    justify-content: space-between
  }

  .page-content {
    padding: 1em 0;
  }
}

.filter-set {
  display: flex;
  align-items: center;
  margin-right: 1em;
}
.publication-wrapper {
  margin: 0 0 1em 0 !important;
  h2 {
    font-size: medium !important;
  }
}

@media only screen and (max-width: 2100px) {

}

@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1280px) {

}

@media only screen and (max-width: 920px) {

}

@media only screen and (max-width: 700px) {

  .page-content.publications {
    padding-right: 0;
    padding-left: 0;
  }
  .title-block {

    .search-filters {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 1em;

      .filters {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 420px) {


}
</style>
